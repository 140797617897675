/*!
 * Theme Name:Harvey Lets Theme
 * Description:Harvey Lets Theme forHarvey Lets website
 * Author: Lyles Sutherland Ltd.
 * Template: Lyles-Sutherland-Theme
 * Version: 0.0.1
 * Year: 06/2019
*/
@import "vendor/bootstrap/bootstrap";
@import "vendor/fontawesome/font-awesome";
@import "vendor/slick/slick";
@import "vendor/slick/slick-theme";
@import "vendor/jquery-cookiebar/jquery-cookiebar";

@import "utils/variables";
@import "utils/responsive";
@import "utils/mixins";
@import "utils/typography";
@import "utils/basic";

@import "components/assets";
@import "components/buttons";
@import "components/forms";
@import "components/pagination";
@import "components/comment-form";
@import "components/comments";
@import "components/accordions";

@import "vendor/gravity_forms/gf-error";
@import "vendor/gravity_forms/gf-gdpr";
@import "vendor/gravity_forms/gf-fields";
@import "vendor/gravity_forms/gf-general";

@import "layout/sidebars";
@import "layout/navigations";
@import "layout/footer";
@import "layout/header";
@import "layout/masthead";

@import "teases/post-tease";
@import "teases/staff-tease";
@import "teases/testimonial-tease";

@import "widgets/social-sharing";

@import "wp-properties/properties-pagination";
@import "wp-properties/properties-searchers";
@import "wp-properties/properties";
@import "wp-properties/properties-overview";
@import "wp-properties/single-property";
@import "wp-properties/property-gallery";
@import "wp-properties/properties-assets";
@import "wp-properties/properties-accordions";

@import "pages/front-page";
